.brand__lockup img{
	height: 101px;
	margin: 13px 0 4px 0;
}

// #primary-nav .level-1 {
//     width: 20%;
//     padding: 8px 5px;
// }

.block-menu-block .content {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
}

#primary-nav .level-1 {
    width: 20%;
    @include breakpoint($md) {
        width: 18%;
    }
    padding: 8px 0 18px 0;
    // text-align: left;
    &:last-child {
    	// padding: 8px 0 18px 5px;
    	// text-align: right;
        @include breakpoint($sm) {
            width: 17%;
        }
        @include breakpoint($md) {
            width: 18%;
        }
    }
}

#primary-nav .menu-mlid-1627 {
    @include breakpoint($md) {
        width: 27%;
    }
}

#primary-nav .menu-mlid-1625{
    @include breakpoint($sm) {
        width: 23%;
    }
    @include breakpoint($md) {
        width: 19%;
    }
}

#primary-nav .level-1.is-open {
    @include breakpoint($md){
        &:after{
            // left: 27%;
        }
        &:first-child{
            &:after{
                // left: 14%;
            }
        }
        &:nth-child(4){
            &:after{
                // left: 45%;
            }
        }
        &:last-child{
            &:after{
                // left: 80%;
            }
        }
    }
}

#primary-nav .level-1.active-trail {
    &:after{
        // left: 27%;
    }
    &:first-child{
        &:after{
            // left: 14%;
        }
    }
    &:nth-child(4){
        &:after{
            // left: 45%;
        }
    }
    &:last-child{
        &:after{
            // left: 80%;
        }
    }
}

.footer-contact__site-name {
	line-height: 21px;
    @include breakpoint($md){
    	max-width: 340px;
    }
}

.full-width-div {
    width: 100%;
}

.mission{
    padding-top: 40px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc;
    display: block;
    @include breakpoint($md){
        width: 72.34%;
    }
    margin: 0 auto;
    p{
        line-height: 30px;
        font-size: 19px;
        span{
            color: $wcm-red;
            font-size: 28px;
            text-align: center;
            width: 100%;
            font-family: '1898 Sans', sans-serif;
            float: left;
            font-weight: bold;
        }
    }
}

.map-row{
    width: 100%;
    min-height: 220px;
    //background-image: url("../images/maprow.jpg");
    //background-size: cover;
    float: left;
    #map{
        display: block;
        width: 100%;
        min-height: 220px;
    }
    p{
        text-align:center;
        width: 100%;
        display: block;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0px;
        cursor: pointer;
        a{
            color: #000;
            &:hover{
                text-decoration: none;
            }
        }
        .site-name{
            font-size: 15px;
            font-weight: bold;
            text-align: center;
            font-family: "1898Sans-Bold",sans-serif;
        }
    }
}


.gm-style > div:first-child > div + div > div:last-child > div > div:first-child > div
{
    /* we have to use !important because we are overwritng inline styles */
    background-color: transparent !important;
    box-shadow: none !important;
    width: auto !important;
    height: auto !important;
        border: none!important;
}

/* arrow colour */
.gm-style > div:first-child > div + div > div:last-child > div > div:first-child > div > div > div
{
    background-color: transparent !important;
    display: none;
    box-shadow: none !important;
        border: none!important;
}

/* close button */
.gm-style > div:first-child > div + div > div:last-child > div > div:last-child
{
    margin-right: 5px;
    margin-top: 5px;
    display: none;
}

/* image icon inside close button */
.gm-style > div:first-child > div + div > div:last-child > div > div:last-child > img
{
    display: none;
}

/* positioning of infowindow */
.gm-style-iw
{
    top: 34px !important;
    left: 22px !important;
}
/* Custom Gmap End */



.gray-row {
    float: left;
    @include breakpoint($md){
        background: #d8dbd7 url("../images/homepeople.jpg") no-repeat bottom center;
        min-height: 895px;
        background-size: cover;


    }

    .row {
        margin-top: 17px;
    }

    .col-md-6 {
        width: 100%;
        @include breakpoint($md) {
            width: 50%;
        }
        div {
            @include breakpoint($md) {
                height: 100%;
            }
        }

        .fieldable-panels-pane {
            margin-bottom: 40px;
        }
    }

    background-color: #d8dbd7;
    a{
        display: block;
        @include breakpoint($md) {
            float: left;
        }
        padding: 40px;
        // margin: 17px 0 40px 0;
        border-left: 5px solid $wcm-red;
        border-bottom: none;
        min-height: 210px;
        height: 100%;
        width: 100%;

        h2{
            color: $wcm-red;
            margin: 0 0 35px 0;
        }
        p{
            color: #000;
        }
        &:hover, &:focus{
            background-color: #f0f2f1;
            text-decoration: none;
            border-left: 5px solid #f0f2f1;
        }

        &:after{
            content: " ";
        }
    }
}

.cta_row{
    background-color: #f7f7f7;
    width: 100%;
    min-height: 260px;

    @include breakpoint($md){
        height: 260px;
        float: left;
    }
    .col-md-3{
        padding: 0 15px;
    }
    a{
        float: left;
        width: 100%;
        border-bottom: none;

        h3{
            font-size: 18px;
            line-height: 24px;
            padding: 0 20px;
            &:after{
                content: '\e80d';
                color: #e87722;
                padding-left: 10px;
                font-size: 60%;
                vertical-align: top;
                font-family: "fontello";
                font-style: normal;
                font-weight: normal;
                speak: none;
                display: inline-block;
                text-decoration: inherit;
                text-align: center;
                font-variant: normal;
                text-transform: none;
                line-height: 1em;
                text-decoration: none;
                line-height: 1.5;
            }
        }

        p{
            color: $wcm-dark-orange;
            font-size: 15px;
            line-height: 24px;
            padding: 0 20px;
        }
    }
    h4{
        margin-top: 30px;
        float: left;
        padding: 0 20px;
        a{
            font-size: 15px;
            line-height: 24px;
            color: $wcm-dark-orange;
            font-family: "Open Sans", sans-serrif;
            &:after{
                padding-top: 7px;
            }
        }
    }

    a.hpfon{
        font-size: 22px;
        color: $wcm-red;
        font-family: "1898Sans-Bold",sans-serif;
        padding: 0 20px;
    }
    .fullheight{
        transition: all 0.5s ease;
        top: 0;
        position: relative;
        min-height: 260px;
        float: left;
        &:hover, &:focus{
            text-decoration: none;
            @include breakpoint($md){
                min-height: 322px;
            }
        }
        a:hover, a:focus{
            text-decoration: none;
        }
        &:after{
            content: " ";
        }
        &:hover{
            background-color: $wcm-dark-orange;

            @include breakpoint($md){
                top: -62px;
                //position: relative;
            }
            h3{
                color: $wcm-yellow;
                &:after{
                    color: #fff;
                }
            }
            h4 a{
                color: $wcm-yellow;
            }
            h4.wcc-cornell{
                background-color: #fff;
            }
            p,a{
                color: #fff;
            }
        }
    }
    h4.wcc-cornell{
        width: 100%;
        //background-color: #fff;
        display: block;
        color: $wcm-red;
        font-size: 15px;
        line-height: 27px;
        text-align: center;
        padding: 0;
        span.wcc-connect{
            font-family: "1898Sans-Regular",sans-serif;
            color: #e87722;
            font-size: 15px;;
            font-weight: 400;
            display: inline-block;
        }
    }
}

.makediff{
    h2{
        text-align: center;
        width: 100%;
        font-size: 38px;
        margin: 60px 0 22px 0;
    }
    p{
        text-align: center;
        width: 100%;
        font-size: 14px;
        line-height: 25px;
    }
    .wcm-cta__tile{
        width: 100%;
        border-width: 0px;
    }
}

.pane-donors-panel-pane-1, .pane-donors-panel-pane-2, .pane-donors-panel-pane-3{
    clear: both;
    h3{
        font-size: 20px;
        text-align: center;
        margin-bottom: 35px;
    }
    .view-content{
        margin-bottom: 10px;
    }

    .field-content{
        a{
            display: block;
            margin: 0 auto;
            text-align: center;
            max-width: 193px;
            height: 193px;
            border: 1px solid #fff;
            &:hover{
                border-color: #ddd;
                border-radius: 96.5px;
                overflow: hidden;
                box-shadow:  0px 0px 10px #888888;
            }
            img{
                margin-top: 30px;
                max-width: 150px;
            }
        }
    }
}

.pane-donors-panel-pane-2{
    margin-top: 70px;
    .field-content{
        a{
            max-width: 193px;
            height: 180px;
            img{
                max-width: 125px;
            }
        }
    }
}

.information-column{
    overflow: hidden;
}

a.rhspho{
    color: #000;
    font-size: 26px;
    text-align: center;
    margin-bottom: 40px;
    font-weight: 600;
    float: left;
    width: 100%;
    border: none;
}

.cpsoc{
    border-bottom: none;
    &:hover {
        border-bottom: none;
    }
    display: inline-block;
    svg{
        width: 25px;
        height: 25px;
        fill: $wcm-white;
        color: #cf4520;
    }
    // .cta-icon-youtube{
    //     margin: 35px 35px 0;
    // }
    // .cta-icon-facebook{

    //     margin-top: 35px;
    // }
    // .cta-icon-twitter{
    //     margin-top: 35px;
    // }

    .cta-icon {
        margin: 35px 17px 0;
    }
}
.socrow{
    float: left;
    border-bottom: 2px solid #dcdcdc;
    margin: 0 0 17px;
    padding: 0 0 25px;
    width: 100%;
    a {
        border: none;
    }
    a.external-link:after{
        display: none;
        content: " ";
    }
}

.jump-to-top>a span{
    border-top: none;
}

//Treatment & Recomendations View

.pane-guideline-and-recommendations-panel-pane-1{
    .views-row{
        float: left;
        border-bottom: 1px solid #dcdcdc;
        display: block;
        padding-bottom: 42px;
        .views-field-title{
            margin-bottom: 15px;
        }
        .views-field-field-guideline-image{
            float: right;
            padding: 0 0 0 50px;
        }
        .views-field-body{

            a.views-more-link{
                display: table;
                background-color: $wcm-dark-orange;
                height: 30px;
                line-height: 30px;
                padding: 0 16px;
                border-radius: 8px;
                color: #fff;
                font-size: 12px;
                margin-top: 22px;
                &:hover{
                    text-decoration: none;
                    background-color: $wcm-bright-orange;
                }
            }
        }
        &:last-child{
            border-bottom: none;
        }
    }
}


.patctas{
    display: block;
    margin: 20px 0 0 0;
    a{
        display: block;
        width: 100%;
        background-color: $wcm-red;
        background-repeat: no-repeat;
        background-position: right 8.5% center;
        background-image: url("../images/Layer_870.png");
        background-size: 70px auto;
        min-height: 300px;
        color: #fff;
        &:hover{
            color: #fff!important;
            background-color: #880501;
        }


        h2{
            color: #fff;
            background-color: $wcm-dark-orange;
            display: block;
            margin-top: 0;
            //width: 72%;
            padding: 45px 35px 0 35px;
            font-size: 40px;
            min-height: 133px;
                 @include breakpoint($sm){
                width: 72%;
            }
        }
        p{
            color: #fff;
            background-color: $wcm-dark-orange;
            display: inline-block;
            padding: 30px 35px 20px 35px;
            min-height: 167px;
            margin-bottom: 0;
            @include breakpoint($sm){
                width: 72%;
                //margin-bottom: 0;
            }
        }
    }
}


/*About Us*/
.patctas.lship a{
    background-image : url("../images/SVG/personico.svg");
    background-size: 94px 90px;
}

.patctas.team a{
    background-image : url("../images/SVG/personsico.svg");
    background-size: 115px 101px;
}

.patctas.research a{
    background-image : url("../images/SVG/labs.svg");
    background-size: 115px 101px;
    // background-position: right 8.5% center;
}

.patctas.diff a{
    background-image : url("../images/SVG/give.svg");
    background-size: 115px 101px;
    // background-position: right 8.5% center;
}

/*Pathients CTAs*/
 .patctas.treat a{
    background-image : url("../images/SVG/fork.svg");
    background-size: 115px 101px;
    background-position: right 8% center;
 }

 .patctas.faqs a{
    background-image : url("../images/SVG/shoe.svg");
    background-size: 115px 101px;
    background-position: right 8% center;
 }

 .patctas.cts a{
    background-image : url("../images/SVG/branch.svg");
    background-size: 115px 101px;
    // background-position: right 8.5% center;
 }

 .patctas.news a{
    background-image : url("../images/SVG/straight.svg");
    background-size: 115px 101px;
    background-position: right 8% center;
 }

 .patctas.reseources a{
    background-image : url("../images/SVG/pivot.svg");
    background-size: 95px auto;
    background-position: right 8% center;
 }

 .patctas.patref a{
    background-image: url("../images/Layer_870r.png");
 }

 .patctas.featpub a{
    background-image : url("../images/svg/shoe3.svg");
    background-size: 115px auto;
    background-position: right 8% center;
 }

.cycle-slideshow {
    position: relative;
    background-color: #f2f2f2;
    top: 0;
    margin: 20px 0 0 0;
    .cycle-slide{
        width: 100%;
    }
    .carousel-slide{
        width: 100%;
        float: left;
    }
    .slide-image img{
        @include breakpoint($md){
            position: relative;
            width: 55%;
            float: left;
        }
    }
    .slide-text{
	    min-height: 100% !important;
	    background: #f2f2f2 !important;
        padding: 0 !important;
    	margin: 0;
        @include breakpoint($sm) {
         	min-height: 100% !important;
        }
		@include breakpoint($md){
            position: relative;
            width: 45%;
            float: left;
            background-color: transparent;
            top: 0;
            h2{
                width: 100%;
                padding: 0;
                color: #555;
                font-size: 20px;
                line-height: 1.3;
                text-align: left;
                margin-top: 70px;
                margin-bottom: 8px;
                a{
                    color: #333;
                }
            }
            p{
                width: 100%;
                padding: 0;
                color: #555;
                font-size: 18px;
                line-height: 1.5;
                a{
                    color: $wcm-bright-orange;
                }
            }
        }
        >.container{
            padding-left: 0;
            padding-right: 10px;
            margin-bottom: 10px;
			@include breakpoint($sm) {
			  padding-right: 50px;
			}
        }
		h2 {
			a {
			 	color: #333 !important;
		  }
		}
	}
    .carousel-image-thumbnail{
        display: none;
    }
}

/* =Thumbail Pager
  ----------------------------------------------------------------------------------*/
  // The thumbnail pager is not displayed in mobile view
  .thumbnail-controller {
    display: none;
    @include breakpoint($sm) {
      display: block;
      background: $wcm-dark-gray;
      border-top: 1px solid #fff;
      padding: 6px 0;
      overflow: hidden;
    }

    .thumbnail-controller-wrap {
      float: left;
      // Center a variable-width element
      position:relative;
      left:50%;

      .thumbnail-controller-wrap-inner {
        position:relative;
        left:-50%;

        .pager{
          font-size: 12px;
          float: left;
          padding-top: 0;
          text-transform: uppercase;
        }

        .pager.previous{
          margin-right: 10px;
        }

        .pager.next{
          margin-left: 10px;
        }

        #thumbnail-pager {
          float: left;

          a{
            padding: 0 10px 0 0;

            &:last-of-type{
              padding: 0;
            }

            > img{
              max-width: none;
              width: 120px;
              height: 48px;
              border-radius: 0;
              border: 3px solid $wcm-border-gray;
              opacity: .7;
              filter: alpha(opacity=70);

            }
          }

          a > img:hover,
          .cycle-pager-active > img{
            border: 3px solid $wcm-bright-orange;
            opacity: 1;
            filter: alpha(opacity=100);
          }
        }//#thumbnail-pager

      }//.thumbnail-controller-wrap-inner

    }//.thumbnail-controller-wrap

  }//.thumbnail-controller


  /* =Mobile Pager / Slide Counter
  ----------------------------------------------------------------------------------*/
  // The mobile pager is not displayed in tablet or desktop view
  .mobile-pager{
    @include breakpoint($sm) {
      display: none;
    }

    a{
      float: left;
      width: 35%;
      color: #aeb8bf;
      font-family: "SSStandard";
      margin: 2px 0 0;
      font-size: 38px;

      &:hover{
        color: #aeb8bf;
        text-decoration: none;
      }
    }

    .previous{
      text-align: right;
    }

    .next {
      text-align: left;
    }

    p#slide-counter{
      float: left;
      width: 20%;
      background: $wcm-med-gray;
      color: #fff;
      font-weight: bold;
      margin: 0 15px;
      padding: 15px 0;

      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      font-size: 12px;
      text-align: center;
    }

  }//.mobile-pager

.mobile-pager {
	a {
		// width: 30%;
		@include breakpoint($sm) {
			width: 35%;
		}
	}
}

.no-js{
    .thumbnail-controller{
        display: none;
    }
}

.thumbnail-controller{
    background-color: #f2f2f2;
    border-top: 1px solid #ddd;
    width: 100%;
}

.carrow{
    background-color: #f2f2f2;
    display: block;
    width: 100%;
}

.field-collection-item-field-related-treatments{
    border-top: 1px solid #dcdcdc;
    margin-top: 25px;
    &:first-child{
        border-top: none;
    }
}

.treatbtn{
    border-radius: 8px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    a{
        color: #fff;
        content: "Read More";
        line-height: 30px;
    }
}

.pane-node-field-condition-image{
    float: left;
    margin: 0 35px 20px 0;
}
.pane-node-title{
    margin-bottom: 20px;
}

img.panopoly-image-original{
    width: 100%;
}

.hero-section{
    img{
        width: 100%;
    }
}

.post-date{
    color: #76767f;
    text-transform: uppercase;
    font-weight: bold;
}

.pane-news-panel-pane-5{
    background-color: #f7f7f7;
    margin-bottom: 65px;
    box-shadow: 0px 0px 10px #888888;
    h1 {
        padding: 10px 30px 0 30px;
        font-size: 30px;
        word-break: break-word;
        @include breakpoint($sm) {
            font-size: 40px;
            padding: 50px 60px 0 60px;
            word-break: initial;
        }

        a{
            color: #555;
            &:after{
                content: "";
            }
        }
    }
    .teaser-text{
        padding: 10px 30px 45px 30px;
        @include breakpoint($sm) {
            padding: 10px 60px 45px 60px;
        }

        p{
            color: #555;
            margin-bottom: 0;
            display: inline
        }
        a{
            color: $wcm-bright-orange;
        }
    }
}

.pane-news-panel-pane-6{
    .view-teaser{
        border-bottom: 1px solid #dcdcdc;
        .teaser-image{
            float: right;
            margin-bottom: 57px;
            margin-left: 40px;
            img{
                max-width: 246px;
            }
        }
        .views-field-field-news-category{
            a{
                background: url(../images/tag.jpg) no-repeat 0 2px;
                padding-left: 23px;
            }
        }
        div.teaser-text{
            margin-bottom: 12.5px;
            // a{
            //     width: 100%;
            //     display: inline-block;
            //     clear: both;
            //     margin-top: 5px;
            // }


        }
    }
}

//Profiles

.pane-profiles-panel-pane-3{

    h4{
        color: #000;
        font-family: "open sans", sans-serif;
        font-weight: 600;
        font-size: 14px;
    }
    .views-field-field-profile-image{
        float: left;
        margin: 0 30px 20px 0;

    }
    .views-field-field-phone{
        .field-content{
            display: inline;
        }
    }
    .views-field-field-pops-profile-link{
        margin-top: 20px;
        a{
            font-size: 14px;
        }
    }
    .views-field-field-personal-statement{
        display: block;
        clear: both;
        margin-bottom: 50px;
        font-size: 14px;
    }
}

//Team Page
//Leadership & Team
.pane-profiles-panel-pane-4,
.pane-profiles-panel-pane-5{
    float: left;
    width: 100%;
		@include breakpoint($sm) {
			padding-right: 8rem;
		}
		@include breakpoint($md) {
			padding-right: 0;
		}
		.view-content {
			h3{
	        font-size: 28px;
					margin-bottom: 15px;
					&:not(:first-child) {
						padding-top: 5px;
						@include breakpoint($sm) {
							padding-top: 50px;
						}
						@include breakpoint($md) {
							padding-top: 25px;
						}
					}

	    }
		}

    .views-row{
				position: relative;
        display: block;
        float: left;
        width: 100%;
				max-width: 210px;
        height: 320px;
				//margin-bottom: 0.5rem;
				cursor: pointer;
				@include breakpoint($sm) {
					width: 33.3333%;
					max-width: 100%;
					height: 280px;
				}
				@include breakpoint($md) {
					width: 25%;
					height: 230px;
				}
				@include breakpoint($lg) {
					height: 280px;
				}

        .views-field-field-profile-image{
            width: 100%;
            overflow: hidden;
            img{
								width: 210px;
								// height: 280px;
								@include breakpoint($sm) {
									width: 100%;
									height: 280px;
							    min-height: 280px;
								}
								@include breakpoint($md) {
									height: 100%;
									min-height: 100%;
									max-height: 260px;
								}
								@include breakpoint($md) {
									max-height: 315px;
								}
            }
        }

        .views-field-field-phone{
            display: none;
            .field-content{
                display: inline;
            }
        }
        .views-field-field-personal-statement{
            clear: both;
        }

    }
}

// Leadership specifics
.pane-profiles-panel-pane-4 {
	h3{
			font-size: 28px;
			padding-bottom: 15px;
		}
	.views-row {
		margin-bottom: 30px;
	}
		.views-field-field-professional-suffix{
				display: none;
				width: 100%;
				height: 100px;
				position: relative;
				top: -100px;
				line-height: 25px;
				&:after{
						content: '';
						width: 100%;
						height: 100%;
						position: absolute;
						left: 0;
						top: 0;
						z-index: 1;
						background-color: $wcm-red;
						mix-blend-mode: multiply;
				}
				a{
						width: 100%;
						height: 100%;
						color: #fff;
						display: inline-block;
						text-align: center;
						font-size: 14px;
						padding: 2px;
						// @include breakpoint($sm) {
						// 	font-size: 14px;
						// }
				}
				h4{
						color: #fff;
						font-family: 'Open Sans', sans-serrif;
						z-index: 2;
						position: relative;
						font-size: 14px;
						// @include breakpoint($md) {
						// 	font-size: 14px;
						// }
				}
				p{
						color: #fff;
						z-index: 2;
						position: relative;
						line-height: 1.4;
						margin-top: 3px;
				}
		}
		.views-field-nothing {
			display: none;
		}
		.leadership-bio-info {
			font-size: 16px;
			line-height: 25px;
			padding-bottom: 45px
		}
		.smltri{
		    &:before{
		        content: " ";
		        width: 0;
		        height: 0;
		        border-left: 20px solid transparent;
		        border-right: 20px solid transparent;
		        border-top: 20px solid #ac1713;
		        position: absolute;
						bottom: -6%;
						left: 40%;
						bottom: 5.5%;
						@include breakpoint($sm){
							bottom: 5.5%;
						}
						@include breakpoint($md){
							bottom: -21%;
						}
						@include breakpoint($lg){
							bottom: -19%;
						}
		    }
		}

}


//Staff specifics
.pane-profiles-panel-pane-5,
.pane-profiles-panel-pane-6{
    // display: block;
    // float: left;
    // width: 100%;
    // h3{
    //     font-size: 28px;
    //     width: 100%;
    //     float: left;
    //     border-top: 1px solid #ddd;
    //     padding-top: 35px;
    //     margin-bottom: 20px;
    //     margin-top: 44px;
    // }
    // h4.display-name{
    //     font-size: 14px;
    //     color: #000;
    //     font-weight: bold;
    // }
    // .views-field-field-profile-image{
    //     width: 100%;
    //     float: left;
    //     img{
    //        width: 100%;
    //     }
    //     @include breakpoint($sm){
    //         height: 320px;
    //         overflow: hidden;
    //         img{
    //            width: 100%;
    //         }
    //     }
    // }
		.view-content {
			> h3 {
					clear: both;
			}
		}
    .views-field-field-biographical-info{
        clear: both;
        display: none;
    }
    // .views-row{
    //     width: 100%;
    //     @include breakpoint($sm){
    //         width: 33.33%;
    //         float: left;
    //         max-height: 320px;
    //         p{
    //             margin-bottom: 0;
    //         }
    //     }
    // }

    .views-field-field-professional-suffix{
				display: none;
				width: 100%;
				height: 100px;
				position: relative;
				top: -100px;
				line-height: 25px;
				&:after{
						content: '';
						width: 100%;
						height: 100%;
						position: absolute;
						left: 0;
						top: 0;
						z-index: 1;
						background-color: $wcm-red;
						mix-blend-mode: multiply;
				}
				.field-content {
					width: 100%;
					height: 100%;
					color: #fff;
					display: inline-block;
					text-align: center;
					// &:before { // Apply blend mode to this pseudo element
	        //     content: '';
	        //     width: 100%;
	        //     height: 100%;
	        //     position: absolute;
	        //     left: 0;
	        //     top: 0;
	        //     z-index: 1;
	        //     background-color: $wcm-red;
	        //     mix-blend-mode: multiply;
	        // }
				}
        h4{
            color: #fff;
            margin-top: 36px;
            font-family: 'Open Sans', sans-serrif;
            text-align: center;
            z-index: 2;
            position: relative;
						font-size: 14px;
						@include breakpoint($sm) {
							font-size: 16px;
						}
        }

        // top: -120px;
        // @include breakpoint($md){
        //     top: -131px;
        // }
        // @include breakpoint($lg){
        //     top: -120px;
        // }

    }

    nav{
        width: 100%;
        float: left;
    }

    .infoarea{
        font-size: 16px;
        line-height: 25px;
        padding-bottom: 45px;
        float: left;
        h4{
            margin-top: 40px;
        }
        p{
            margin-top: 25px;
            float: left;
        }
        a{
            font-size: 14px;
        }
    }
}

.pane-profiles-panel-pane-5 {
	.smltri{
		&:before{
			content: " ";
			width: 0;
			height: 0;
			border-left: 20px solid transparent;
			border-right: 20px solid transparent;
			border-top: 20px solid #ac1713;
			position: absolute;
			bottom: 6.5%;
			left: 40%;
			@include breakpoint($sm){
				bottom: -6.5%;
			}
			@include breakpoint($md){
				bottom: -9%;
			}
			@include breakpoint($lg){
				bottom: -7.5%;
			}
		}
	}
}

// Conditional related staff specific
.pane-profiles-panel-pane-6{
  h2{
    margin-bottom: 20px;
  }
  .views-row{
		min-height: 225px;
		p.display-name {
			font-weight: bold;
			padding-top: 8px;
		}
		&:not(.smltri) {
			.views-field-field-biographical-info + .views-field-field-profile-image {
				position: relative;
				cursor: pointer;
				&:after {
					content: " ";
					position: absolute;
					background: url(../images/sprite/profile_white.png) no-repeat;
					background-size: 2.6rem;
					background-position: 0px 0px;
					border-width: 12px;
					z-index: 1;
					bottom: 2px;
					left: 82px;
					width: 30px;
					height: 30px;
				}
			}
			&:hover {
				.views-field-field-biographical-info + .views-field-field-profile-image {
					position: relative;
					&:after {
						background: url(../images/sprite/profile.png) no-repeat;
						background-size: 2.6rem;
						background-position: 0px 0px;
					}
					img {
						outline-style: solid;
						outline-color: #e87722;
						outline-width: 3px;
					}
				}
			}
		}
		.views-field-field-profile-image {
			img {
				height: 155px;
				width: auto;
				min-width: 110px;
			}
		}
	}
	.smltri{
		.views-field-field-biographical-info + .views-field-field-profile-image {
			position: relative;
			&:before{
				position: absolute;
				content: " ";
				width: 0;
				height: 0;
				border-left: 20px solid transparent;
				border-right: 20px solid transparent;
				border-top: 20px solid #ac1713;
				bottom: -22px;
				left: 38px;
			}
			&:after {
				content: " ";
				position: absolute;
				background: url(../images/sprite/profile.png) no-repeat;
				background-color: transparent;
				background-size: 2.6rem;
				background-position: 0px 0px;
				border-width: 12px;
				bottom: 2px;
				left: 82px;
				width: 30px;
				height: 30px;
			}
			& + .views-field-nothing {
				p.display-name {
					padding: 0;
					margin: 0;
					display: none;
				}
			}
			img {
				outline-style: solid;
				outline-color: #ac1713;
				outline-width: 3px;
			}
		}
		&:hover {
			.views-field-field-biographical-info + .views-field-field-profile-image {
				&:after {
					background: url(../images/sprite/profile.png) no-repeat;
					background-color: transparent;
					background-size: 2.6rem;
					background-position: 0px 0px;
				}
				img {
					outline-color: #ac1713 !important;
				}
			}
		}
	}
	.infoarea{
		clear: both;
		margin-top: -15px;
		h4 {
			margin: 0;
		}
		.field-content {
			margin-top: 10px;
			padding-left: 20px;
			padding-right: 20px;
		}
		.display-bio {
			p {
				margin-top: 8px;
			}
		}
	}
}

.profile_icon {
	position: relative;
	&:after {
		content: " ";
		position: absolute;
		background: url(../images/sprite/profile.png) no-repeat;
		background-color: transparent;
		background-size: 2.6rem;
		background-position: 0px 0px;
		border-width: 12px;
		z-index: 1;
		bottom: 2px;
		left: 82px;
		width: 30px;
		height: 30px;
	}
}

.pane-profiles-panel-pane-7 {
	display: inline-block;
	padding-top: 35px;
	.views-field-nothing {
    padding: 1rem 0;
		background: #fff;
		font-size: 16px;
		line-height: 25px;
	}
}

.shownm{
    display: block!important;
}

//Biblio

#biblio-header{
    height: 20px;
}

//CT View

.pane-clinical-trials-panel-pane-1{
    .views-row{
        border-bottom: 1px solid #dcdcdc;
        margin: 0 0 10px 0;
        padding: 0 0 20px 0;
        // float: left;
        h2{
            //padding-left: 15px;
            margin-bottom: 20px;
        }
        p{
            //padding-left: 15px;
        }

        .trial-title {
			padding-bottom: 10px;
			font-size: 17px;
        }

        .views-field {
			padding-bottom: 2px;
			.views-label {
				font-weight: 600;
			}
			.field-content {
				display: inline;
			}
        }

        .views-field-field-trial-source-link {
			padding-top: 10px;
		}
    }
    .views-field-nothing{
        float: left;
    }
    .views-field-field-trial-path{
        float: left;
    }
    .views-field-field-trial-key-eligibility{
        //padding-left: 15px;
        ul{
            padding-left: 20px;
        }
    }
    .views-field-field-trial-path{
        .btn-sml{
            margin-left: 15px;
        }
    }
    .btn-sml{
        background-color: $wcm-dark-orange;
        color: #fff;
        border-radius: 10px;
        height: 30px;
        line-height: 30px;
        padding-bottom: 0;
        padding-top: 0;
        font-size: 13px;

        &:hover{
            text-decoration: underline;
        }
    }
}

#views-exposed-form-clinical-trials-panel-pane-1{
    .views-widget-filter-tid{
        width: 100%;
        padding: 0;
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;

        a{
            width: 100%;
            @include breakpoint($md){
                width: 50%;
            }
            float: left;
            border-bottom: 1px solid #ddd;
            border-right: 1px solid #ddd;
            height: 75px;
            line-height: 75px;
            text-align: center;
            font-size: 18px;
            color: #555;
            font-weight: bold;
        }
        a.active{
            background: #ddd url('../images/orngarr.jpg') right 23px center no-repeat !important;
        }
    }
}

h2.cttitle{
    width: 100%;
    display: block;
    background-color: #ddd;
    color: #000;
    height: 42px;
    padding-left: 22px;
    padding-top: 13px;
    font-size: 18px;
    margin: 25px 0;
}

//Make a Diff page

a.anclinks{
    float: left;
    width: 100%;
    color: $wcm-red;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-weight: bold;
    padding: 15px 120px;
    min-height: 120px;
    border: 1px solid #ddd;
    @include breakpoint($md){
        width: 50%;
    }
    &:first-child{
        border-right: none;
    }
    background: url('../images/grydwn.jpg') center 92% no-repeat;
    &:hover{
        background: url('../images/dwnred.jpg') center 92% no-repeat;
    }
}

p.calldiff{
    font-size: 19px;
    width: 100%;
    text-align: center;
    font-weight: bold;
    padding: 40px 0 0 0;
    float: left;
}

a.findiff{
    @include breakpoint($md){
        width: 32%;
    }
    width: 100%;
    margin: 10px 10px 0 0;
    background-color: $wcm-dark-orange;
    float: left;
    &:hover{
        color: #fff;
    }
    h3{
        color: #fff;
        padding: 35px 25px 0;
        font-size: 40px;
        min-height: 205px;
    }
    p{
        color: #fff;
        padding: 0 25px;
    }
    img{
        width: 90%;
        margin: 0 auto 20px;
        display: block;
    }
    margin-bottom: 30px;
}
a.findiff.last{
    margin-right: 0;
}

.reseff{
    background-color: $wcm-dark-orange;
    @include breakpoint($md){
        width: 32%;
    }
    width: 100%;
    margin: 10px 10px 100px 0;
    float: left;
    &:hover{
        color: #fff;
    }
    h3{
        color: #fff;
        line-height: 40px;
        font-size: 30px;
        padding: 25px;
        margin: 0;
        min-height: 175px;
    }
    &:after{
        content: " ";
        display: block;
        float: left;
        width: 100%;
        height: 120px;
        background: $wcm-red url('../images/svg/lifesaver.svg') center center no-repeat;
        color: #fff;
    }
}

.reseff.chart{
    &:after{
        background-image: url('../images/svg/graph.svg');
    }
}

.reseff.eye{
    &:after{
        background-image: url('../images/svg/eye.svg');
    }
}

.reseff.external-link{
    &:after{
        content: " ";
    }
}

#qualfrom{
    height: 3700px;
    max-width: 100%;
    border: 0;
}

.cta-icon{
    -webkit-transform: scale3d(1,1,1);
}

.form-control {
        max-width: none;
    }

.btn-sml{
    background-color: $wcm-dark-orange;
    color: #fff;
    border-radius: 10px;
    height: 30px;
    line-height: 30px;
    padding-bottom: 0;
    padding-top: 0;
    font-size: 13px;
    &:hover{
        text-decoration: underline;
    }
}

.pane-conditions-view-panel-pane-3, .pane-conditions-view-panel-pane-2{
    float: left;
    .views-row {
        float: left;
        width: 100%;
    }
    .views-field-title{
        float: left;
        width: 100%;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .views-field-field-condition-image{
        float: right;
        margin: 0 0 10px 40px;
    }
    .views-field-path{
        margin-top: 25px;
    }
}

.pane-conditions-view-panel-pane-3{
    padding-top: 46px;
    margin-top: 35px;
    border-top: 1px solid #d8d8d8;
}

#related-content-sidebar {
	div.panel-pane {
		margin-bottom: 0 !important;
	}
	.pane-title {
	  line-height: 1.5;
	}
}

.pane-conditions-panel-pane-1{
    span.field-content{
        list-style:disc outside none;
        display:list-item;
        margin-left: 20px;
    }
}

#fincont,#reseff{
    display: block;
    float: left;
    width: 100%;
}

.node-biblio{
    div.submitted{
        display: none;
    }
}

/* Events Page Style */

.pane-news-panel-pane-7{
    .view-teaser{
        width: 100%;
        border-bottom: 1px solid #ddd;
        margin-bottom: 15px;
        .views-field-field-event-date-3{
            float: left;
            width: 125px;
            height: 100px;
            background-color: #ddd;
            margin-right: 30px;
            margin-bottom: 15px;
            span{
                line-height: 1;
            }
            .mondate{
                float: left;
                text-transform: uppercase;
                width: 100%;
                text-align: center;
                font-size: 22px;
                margin-top: 10px;
            }
            .daydate{
                float: left;
                width: 100%;
                text-align: center;
                font-size: 40px;
                font-weight: bold;
            }
            .yrdate{
                float: left;
                width: 100%;
                text-align: center;
                font-size: 16px;
                margin-top: 1px;
            }
            &:after{
                position: relative;
                top: 30px;
                left: 100%;
                border-color: transparent #ddd;
                border-style: solid;
                border-width: 20px 0px 20px 20px;
                height: 1px;
                width: 0px;
                content: " ";
                display: block;
            }
        }

        h3{
            margin-top: 0;
            font-size: 16px;
            margin-top: 5px;
        }
        .views-label{
            float: left;
            font-weight: bold;
            padding-right: 5px;
        }
    }
}

.pane-node-field-related-treatments, .pane-profiles-panel-pane-6, .pane-node-field-treatment-goals{
    border-top: 1px solid #ddd;
}

.pane-weight-panel-pane-1,
.pane-weight-panel-pane-2 {
	.pane-title {
		padding-bottom: 25px;
	}
	table {
		caption {
			font-size: 20px;
			color: #b31b1b;
			padding-bottom: 10px;
			font-weight: 600;
			text-align: left;
		}
	}
}


