/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
.brand__lockup img {
  height: 101px;
  margin: 13px 0 4px 0; }

.block-menu-block .content {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px; }

#primary-nav .level-1 {
  width: 20%;
  padding: 8px 0 18px 0; }
  @media screen and (min-width: 992px) {
    #primary-nav .level-1 {
      width: 18%; } }
  @media screen and (min-width: 768px) {
    #primary-nav .level-1:last-child {
      width: 17%; } }
  @media screen and (min-width: 992px) {
    #primary-nav .level-1:last-child {
      width: 18%; } }

@media screen and (min-width: 992px) {
  #primary-nav .menu-mlid-1627 {
    width: 27%; } }

@media screen and (min-width: 768px) {
  #primary-nav .menu-mlid-1625 {
    width: 23%; } }

@media screen and (min-width: 992px) {
  #primary-nav .menu-mlid-1625 {
    width: 19%; } }

.footer-contact__site-name {
  line-height: 21px; }
  @media screen and (min-width: 992px) {
    .footer-contact__site-name {
      max-width: 340px; } }

.full-width-div {
  width: 100%; }

.mission {
  padding-top: 40px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ccc;
  display: block;
  margin: 0 auto; }
  @media screen and (min-width: 992px) {
    .mission {
      width: 72.34%; } }
  .mission p {
    line-height: 30px;
    font-size: 19px; }
    .mission p span {
      color: #b31b1b;
      font-size: 28px;
      text-align: center;
      width: 100%;
      font-family: '1898 Sans', sans-serif;
      float: left;
      font-weight: bold; }

.map-row {
  width: 100%;
  min-height: 220px;
  float: left; }
  .map-row #map {
    display: block;
    width: 100%;
    min-height: 220px; }
  .map-row p {
    text-align: center;
    width: 100%;
    display: block;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0px;
    cursor: pointer; }
    .map-row p a {
      color: #000; }
      .map-row p a:hover {
        text-decoration: none; }
    .map-row p .site-name {
      font-size: 15px;
      font-weight: bold;
      text-align: center;
      font-family: "1898Sans-Bold",sans-serif; }

.gm-style > div:first-child > div + div > div:last-child > div > div:first-child > div {
  /* we have to use !important because we are overwritng inline styles */
  background-color: transparent !important;
  box-shadow: none !important;
  width: auto !important;
  height: auto !important;
  border: none !important; }

/* arrow colour */
.gm-style > div:first-child > div + div > div:last-child > div > div:first-child > div > div > div {
  background-color: transparent !important;
  display: none;
  box-shadow: none !important;
  border: none !important; }

/* close button */
.gm-style > div:first-child > div + div > div:last-child > div > div:last-child {
  margin-right: 5px;
  margin-top: 5px;
  display: none; }

/* image icon inside close button */
.gm-style > div:first-child > div + div > div:last-child > div > div:last-child > img {
  display: none; }

/* positioning of infowindow */
.gm-style-iw {
  top: 34px !important;
  left: 22px !important; }

/* Custom Gmap End */
.gray-row {
  float: left;
  background-color: #d8dbd7; }
  @media screen and (min-width: 992px) {
    .gray-row {
      background: #d8dbd7 url("../images/homepeople.jpg") no-repeat bottom center;
      min-height: 895px;
      background-size: cover; } }
  .gray-row .row {
    margin-top: 17px; }
  .gray-row .col-md-6 {
    width: 100%; }
    @media screen and (min-width: 992px) {
      .gray-row .col-md-6 {
        width: 50%; } }
    @media screen and (min-width: 992px) {
      .gray-row .col-md-6 div {
        height: 100%; } }
    .gray-row .col-md-6 .fieldable-panels-pane {
      margin-bottom: 40px; }
  .gray-row a {
    display: block;
    padding: 40px;
    border-left: 5px solid #b31b1b;
    border-bottom: none;
    min-height: 210px;
    height: 100%;
    width: 100%; }
    @media screen and (min-width: 992px) {
      .gray-row a {
        float: left; } }
    .gray-row a h2 {
      color: #b31b1b;
      margin: 0 0 35px 0; }
    .gray-row a p {
      color: #000; }
    .gray-row a:hover, .gray-row a:focus {
      background-color: #f0f2f1;
      text-decoration: none;
      border-left: 5px solid #f0f2f1; }
    .gray-row a:after {
      content: " "; }

.cta_row {
  background-color: #f7f7f7;
  width: 100%;
  min-height: 260px; }
  @media screen and (min-width: 992px) {
    .cta_row {
      height: 260px;
      float: left; } }
  .cta_row .col-md-3 {
    padding: 0 15px; }
  .cta_row a {
    float: left;
    width: 100%;
    border-bottom: none; }
    .cta_row a h3 {
      font-size: 18px;
      line-height: 24px;
      padding: 0 20px; }
      .cta_row a h3:after {
        content: '\e80d';
        color: #e87722;
        padding-left: 10px;
        font-size: 60%;
        vertical-align: top;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        text-decoration: none;
        line-height: 1.5; }
    .cta_row a p {
      color: #cf4520;
      font-size: 15px;
      line-height: 24px;
      padding: 0 20px; }
  .cta_row h4 {
    margin-top: 30px;
    float: left;
    padding: 0 20px; }
    .cta_row h4 a {
      font-size: 15px;
      line-height: 24px;
      color: #cf4520;
      font-family: "Open Sans", sans-serrif; }
      .cta_row h4 a:after {
        padding-top: 7px; }
  .cta_row a.hpfon {
    font-size: 22px;
    color: #b31b1b;
    font-family: "1898Sans-Bold",sans-serif;
    padding: 0 20px; }
  .cta_row .fullheight {
    transition: all 0.5s ease;
    top: 0;
    position: relative;
    min-height: 260px;
    float: left; }
    .cta_row .fullheight:hover, .cta_row .fullheight:focus {
      text-decoration: none; }
      @media screen and (min-width: 992px) {
        .cta_row .fullheight:hover, .cta_row .fullheight:focus {
          min-height: 322px; } }
    .cta_row .fullheight a:hover, .cta_row .fullheight a:focus {
      text-decoration: none; }
    .cta_row .fullheight:after {
      content: " "; }
    .cta_row .fullheight:hover {
      background-color: #cf4520; }
      @media screen and (min-width: 992px) {
        .cta_row .fullheight:hover {
          top: -62px; } }
      .cta_row .fullheight:hover h3 {
        color: #ffc72c; }
        .cta_row .fullheight:hover h3:after {
          color: #fff; }
      .cta_row .fullheight:hover h4 a {
        color: #ffc72c; }
      .cta_row .fullheight:hover h4.wcc-cornell {
        background-color: #fff; }
      .cta_row .fullheight:hover p, .cta_row .fullheight:hover a {
        color: #fff; }
  .cta_row h4.wcc-cornell {
    width: 100%;
    display: block;
    color: #b31b1b;
    font-size: 15px;
    line-height: 27px;
    text-align: center;
    padding: 0; }
    .cta_row h4.wcc-cornell span.wcc-connect {
      font-family: "1898Sans-Regular",sans-serif;
      color: #e87722;
      font-size: 15px;
      font-weight: 400;
      display: inline-block; }

.makediff h2 {
  text-align: center;
  width: 100%;
  font-size: 38px;
  margin: 60px 0 22px 0; }

.makediff p {
  text-align: center;
  width: 100%;
  font-size: 14px;
  line-height: 25px; }

.makediff .wcm-cta__tile {
  width: 100%;
  border-width: 0px; }

.pane-donors-panel-pane-1, .pane-donors-panel-pane-2, .pane-donors-panel-pane-3 {
  clear: both; }
  .pane-donors-panel-pane-1 h3, .pane-donors-panel-pane-2 h3, .pane-donors-panel-pane-3 h3 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 35px; }
  .pane-donors-panel-pane-1 .view-content, .pane-donors-panel-pane-2 .view-content, .pane-donors-panel-pane-3 .view-content {
    margin-bottom: 10px; }
  .pane-donors-panel-pane-1 .field-content a, .pane-donors-panel-pane-2 .field-content a, .pane-donors-panel-pane-3 .field-content a {
    display: block;
    margin: 0 auto;
    text-align: center;
    max-width: 193px;
    height: 193px;
    border: 1px solid #fff; }
    .pane-donors-panel-pane-1 .field-content a:hover, .pane-donors-panel-pane-2 .field-content a:hover, .pane-donors-panel-pane-3 .field-content a:hover {
      border-color: #ddd;
      border-radius: 96.5px;
      overflow: hidden;
      box-shadow: 0px 0px 10px #888888; }
    .pane-donors-panel-pane-1 .field-content a img, .pane-donors-panel-pane-2 .field-content a img, .pane-donors-panel-pane-3 .field-content a img {
      margin-top: 30px;
      max-width: 150px; }

.pane-donors-panel-pane-2 {
  margin-top: 70px; }
  .pane-donors-panel-pane-2 .field-content a {
    max-width: 193px;
    height: 180px; }
    .pane-donors-panel-pane-2 .field-content a img {
      max-width: 125px; }

.information-column {
  overflow: hidden; }

a.rhspho {
  color: #000;
  font-size: 26px;
  text-align: center;
  margin-bottom: 40px;
  font-weight: 600;
  float: left;
  width: 100%;
  border: none; }

.cpsoc {
  border-bottom: none;
  display: inline-block; }
  .cpsoc:hover {
    border-bottom: none; }
  .cpsoc svg {
    width: 25px;
    height: 25px;
    fill: #fff;
    color: #cf4520; }
  .cpsoc .cta-icon {
    margin: 35px 17px 0; }

.socrow {
  float: left;
  border-bottom: 2px solid #dcdcdc;
  margin: 0 0 17px;
  padding: 0 0 25px;
  width: 100%; }
  .socrow a {
    border: none; }
  .socrow a.external-link:after {
    display: none;
    content: " "; }

.jump-to-top > a span {
  border-top: none; }

.pane-guideline-and-recommendations-panel-pane-1 .views-row {
  float: left;
  border-bottom: 1px solid #dcdcdc;
  display: block;
  padding-bottom: 42px; }
  .pane-guideline-and-recommendations-panel-pane-1 .views-row .views-field-title {
    margin-bottom: 15px; }
  .pane-guideline-and-recommendations-panel-pane-1 .views-row .views-field-field-guideline-image {
    float: right;
    padding: 0 0 0 50px; }
  .pane-guideline-and-recommendations-panel-pane-1 .views-row .views-field-body a.views-more-link {
    display: table;
    background-color: #cf4520;
    height: 30px;
    line-height: 30px;
    padding: 0 16px;
    border-radius: 8px;
    color: #fff;
    font-size: 12px;
    margin-top: 22px; }
    .pane-guideline-and-recommendations-panel-pane-1 .views-row .views-field-body a.views-more-link:hover {
      text-decoration: none;
      background-color: #e7751d; }
  .pane-guideline-and-recommendations-panel-pane-1 .views-row:last-child {
    border-bottom: none; }

.patctas {
  display: block;
  margin: 20px 0 0 0; }
  .patctas a {
    display: block;
    width: 100%;
    background-color: #b31b1b;
    background-repeat: no-repeat;
    background-position: right 8.5% center;
    background-image: url("../images/Layer_870.png");
    background-size: 70px auto;
    min-height: 300px;
    color: #fff; }
    .patctas a:hover {
      color: #fff !important;
      background-color: #880501; }
    .patctas a h2 {
      color: #fff;
      background-color: #cf4520;
      display: block;
      margin-top: 0;
      padding: 45px 35px 0 35px;
      font-size: 40px;
      min-height: 133px; }
      @media screen and (min-width: 768px) {
        .patctas a h2 {
          width: 72%; } }
    .patctas a p {
      color: #fff;
      background-color: #cf4520;
      display: inline-block;
      padding: 30px 35px 20px 35px;
      min-height: 167px;
      margin-bottom: 0; }
      @media screen and (min-width: 768px) {
        .patctas a p {
          width: 72%; } }

/*About Us*/
.patctas.lship a {
  background-image: url("../images/SVG/personico.svg");
  background-size: 94px 90px; }

.patctas.team a {
  background-image: url("../images/SVG/personsico.svg");
  background-size: 115px 101px; }

.patctas.research a {
  background-image: url("../images/SVG/labs.svg");
  background-size: 115px 101px; }

.patctas.diff a {
  background-image: url("../images/SVG/give.svg");
  background-size: 115px 101px; }

/*Pathients CTAs*/
.patctas.treat a {
  background-image: url("../images/SVG/fork.svg");
  background-size: 115px 101px;
  background-position: right 8% center; }

.patctas.faqs a {
  background-image: url("../images/SVG/shoe.svg");
  background-size: 115px 101px;
  background-position: right 8% center; }

.patctas.cts a {
  background-image: url("../images/SVG/branch.svg");
  background-size: 115px 101px; }

.patctas.news a {
  background-image: url("../images/SVG/straight.svg");
  background-size: 115px 101px;
  background-position: right 8% center; }

.patctas.reseources a {
  background-image: url("../images/SVG/pivot.svg");
  background-size: 95px auto;
  background-position: right 8% center; }

.patctas.patref a {
  background-image: url("../images/Layer_870r.png"); }

.patctas.featpub a {
  background-image: url("../images/svg/shoe3.svg");
  background-size: 115px auto;
  background-position: right 8% center; }

.cycle-slideshow {
  position: relative;
  background-color: #f2f2f2;
  top: 0;
  margin: 20px 0 0 0; }
  .cycle-slideshow .cycle-slide {
    width: 100%; }
  .cycle-slideshow .carousel-slide {
    width: 100%;
    float: left; }
  @media screen and (min-width: 992px) {
    .cycle-slideshow .slide-image img {
      position: relative;
      width: 55%;
      float: left; } }
  .cycle-slideshow .slide-text {
    min-height: 100% !important;
    background: #f2f2f2 !important;
    padding: 0 !important;
    margin: 0; }
    @media screen and (min-width: 768px) {
      .cycle-slideshow .slide-text {
        min-height: 100% !important; } }
    @media screen and (min-width: 992px) {
      .cycle-slideshow .slide-text {
        position: relative;
        width: 45%;
        float: left;
        background-color: transparent;
        top: 0; }
        .cycle-slideshow .slide-text h2 {
          width: 100%;
          padding: 0;
          color: #555;
          font-size: 20px;
          line-height: 1.3;
          text-align: left;
          margin-top: 70px;
          margin-bottom: 8px; }
          .cycle-slideshow .slide-text h2 a {
            color: #333; }
        .cycle-slideshow .slide-text p {
          width: 100%;
          padding: 0;
          color: #555;
          font-size: 18px;
          line-height: 1.5; }
          .cycle-slideshow .slide-text p a {
            color: #e7751d; } }
    .cycle-slideshow .slide-text > .container {
      padding-left: 0;
      padding-right: 10px;
      margin-bottom: 10px; }
      @media screen and (min-width: 768px) {
        .cycle-slideshow .slide-text > .container {
          padding-right: 50px; } }
    .cycle-slideshow .slide-text h2 a {
      color: #333 !important; }
  .cycle-slideshow .carousel-image-thumbnail {
    display: none; }

/* =Thumbail Pager
  ----------------------------------------------------------------------------------*/
.thumbnail-controller {
  display: none; }
  @media screen and (min-width: 768px) {
    .thumbnail-controller {
      display: block;
      background: #555555;
      border-top: 1px solid #fff;
      padding: 6px 0;
      overflow: hidden; } }
  .thumbnail-controller .thumbnail-controller-wrap {
    float: left;
    position: relative;
    left: 50%; }
    .thumbnail-controller .thumbnail-controller-wrap .thumbnail-controller-wrap-inner {
      position: relative;
      left: -50%; }
      .thumbnail-controller .thumbnail-controller-wrap .thumbnail-controller-wrap-inner .pager {
        font-size: 12px;
        float: left;
        padding-top: 0;
        text-transform: uppercase; }
      .thumbnail-controller .thumbnail-controller-wrap .thumbnail-controller-wrap-inner .pager.previous {
        margin-right: 10px; }
      .thumbnail-controller .thumbnail-controller-wrap .thumbnail-controller-wrap-inner .pager.next {
        margin-left: 10px; }
      .thumbnail-controller .thumbnail-controller-wrap .thumbnail-controller-wrap-inner #thumbnail-pager {
        float: left; }
        .thumbnail-controller .thumbnail-controller-wrap .thumbnail-controller-wrap-inner #thumbnail-pager a {
          padding: 0 10px 0 0; }
          .thumbnail-controller .thumbnail-controller-wrap .thumbnail-controller-wrap-inner #thumbnail-pager a:last-of-type {
            padding: 0; }
          .thumbnail-controller .thumbnail-controller-wrap .thumbnail-controller-wrap-inner #thumbnail-pager a > img {
            max-width: none;
            width: 120px;
            height: 48px;
            border-radius: 0;
            border: 3px solid #dddddd;
            opacity: .7;
            filter: alpha(opacity=70); }
        .thumbnail-controller .thumbnail-controller-wrap .thumbnail-controller-wrap-inner #thumbnail-pager a > img:hover,
        .thumbnail-controller .thumbnail-controller-wrap .thumbnail-controller-wrap-inner #thumbnail-pager .cycle-pager-active > img {
          border: 3px solid #e7751d;
          opacity: 1;
          filter: alpha(opacity=100); }

/* =Mobile Pager / Slide Counter
  ----------------------------------------------------------------------------------*/
@media screen and (min-width: 768px) {
  .mobile-pager {
    display: none; } }

.mobile-pager a {
  float: left;
  width: 35%;
  color: #aeb8bf;
  font-family: "SSStandard";
  margin: 2px 0 0;
  font-size: 38px; }
  .mobile-pager a:hover {
    color: #aeb8bf;
    text-decoration: none; }

.mobile-pager .previous {
  text-align: right; }

.mobile-pager .next {
  text-align: left; }

.mobile-pager p#slide-counter {
  float: left;
  width: 20%;
  background: #666666;
  color: #fff;
  font-weight: bold;
  margin: 0 15px;
  padding: 15px 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 12px;
  text-align: center; }

@media screen and (min-width: 768px) {
  .mobile-pager a {
    width: 35%; } }

.no-js .thumbnail-controller {
  display: none; }

.thumbnail-controller {
  background-color: #f2f2f2;
  border-top: 1px solid #ddd;
  width: 100%; }

.carrow {
  background-color: #f2f2f2;
  display: block;
  width: 100%; }

.field-collection-item-field-related-treatments {
  border-top: 1px solid #dcdcdc;
  margin-top: 25px; }
  .field-collection-item-field-related-treatments:first-child {
    border-top: none; }

.treatbtn {
  border-radius: 8px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 20px;
  margin-bottom: 20px; }
  .treatbtn a {
    color: #fff;
    content: "Read More";
    line-height: 30px; }

.pane-node-field-condition-image {
  float: left;
  margin: 0 35px 20px 0; }

.pane-node-title {
  margin-bottom: 20px; }

img.panopoly-image-original {
  width: 100%; }

.hero-section img {
  width: 100%; }

.post-date {
  color: #76767f;
  text-transform: uppercase;
  font-weight: bold; }

.pane-news-panel-pane-5 {
  background-color: #f7f7f7;
  margin-bottom: 65px;
  box-shadow: 0px 0px 10px #888888; }
  .pane-news-panel-pane-5 h1 {
    padding: 10px 30px 0 30px;
    font-size: 30px;
    word-break: break-word; }
    @media screen and (min-width: 768px) {
      .pane-news-panel-pane-5 h1 {
        font-size: 40px;
        padding: 50px 60px 0 60px;
        word-break: initial; } }
    .pane-news-panel-pane-5 h1 a {
      color: #555; }
      .pane-news-panel-pane-5 h1 a:after {
        content: ""; }
  .pane-news-panel-pane-5 .teaser-text {
    padding: 10px 30px 45px 30px; }
    @media screen and (min-width: 768px) {
      .pane-news-panel-pane-5 .teaser-text {
        padding: 10px 60px 45px 60px; } }
    .pane-news-panel-pane-5 .teaser-text p {
      color: #555;
      margin-bottom: 0;
      display: inline; }
    .pane-news-panel-pane-5 .teaser-text a {
      color: #e7751d; }

.pane-news-panel-pane-6 .view-teaser {
  border-bottom: 1px solid #dcdcdc; }
  .pane-news-panel-pane-6 .view-teaser .teaser-image {
    float: right;
    margin-bottom: 57px;
    margin-left: 40px; }
    .pane-news-panel-pane-6 .view-teaser .teaser-image img {
      max-width: 246px; }
  .pane-news-panel-pane-6 .view-teaser .views-field-field-news-category a {
    background: url(../images/tag.jpg) no-repeat 0 2px;
    padding-left: 23px; }
  .pane-news-panel-pane-6 .view-teaser div.teaser-text {
    margin-bottom: 12.5px; }

.pane-profiles-panel-pane-3 h4 {
  color: #000;
  font-family: "open sans", sans-serif;
  font-weight: 600;
  font-size: 14px; }

.pane-profiles-panel-pane-3 .views-field-field-profile-image {
  float: left;
  margin: 0 30px 20px 0; }

.pane-profiles-panel-pane-3 .views-field-field-phone .field-content {
  display: inline; }

.pane-profiles-panel-pane-3 .views-field-field-pops-profile-link {
  margin-top: 20px; }
  .pane-profiles-panel-pane-3 .views-field-field-pops-profile-link a {
    font-size: 14px; }

.pane-profiles-panel-pane-3 .views-field-field-personal-statement {
  display: block;
  clear: both;
  margin-bottom: 50px;
  font-size: 14px; }

.pane-profiles-panel-pane-4,
.pane-profiles-panel-pane-5 {
  float: left;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .pane-profiles-panel-pane-4,
    .pane-profiles-panel-pane-5 {
      padding-right: 8rem; } }
  @media screen and (min-width: 992px) {
    .pane-profiles-panel-pane-4,
    .pane-profiles-panel-pane-5 {
      padding-right: 0; } }
  .pane-profiles-panel-pane-4 .view-content h3,
  .pane-profiles-panel-pane-5 .view-content h3 {
    font-size: 28px;
    margin-bottom: 15px; }
    .pane-profiles-panel-pane-4 .view-content h3:not(:first-child),
    .pane-profiles-panel-pane-5 .view-content h3:not(:first-child) {
      padding-top: 5px; }
      @media screen and (min-width: 768px) {
        .pane-profiles-panel-pane-4 .view-content h3:not(:first-child),
        .pane-profiles-panel-pane-5 .view-content h3:not(:first-child) {
          padding-top: 50px; } }
      @media screen and (min-width: 992px) {
        .pane-profiles-panel-pane-4 .view-content h3:not(:first-child),
        .pane-profiles-panel-pane-5 .view-content h3:not(:first-child) {
          padding-top: 25px; } }
  .pane-profiles-panel-pane-4 .views-row,
  .pane-profiles-panel-pane-5 .views-row {
    position: relative;
    display: block;
    float: left;
    width: 100%;
    max-width: 210px;
    height: 320px;
    cursor: pointer; }
    @media screen and (min-width: 768px) {
      .pane-profiles-panel-pane-4 .views-row,
      .pane-profiles-panel-pane-5 .views-row {
        width: 33.3333%;
        max-width: 100%;
        height: 280px; } }
    @media screen and (min-width: 992px) {
      .pane-profiles-panel-pane-4 .views-row,
      .pane-profiles-panel-pane-5 .views-row {
        width: 25%;
        height: 230px; } }
    @media screen and (min-width: 1200px) {
      .pane-profiles-panel-pane-4 .views-row,
      .pane-profiles-panel-pane-5 .views-row {
        height: 280px; } }
    .pane-profiles-panel-pane-4 .views-row .views-field-field-profile-image,
    .pane-profiles-panel-pane-5 .views-row .views-field-field-profile-image {
      width: 100%;
      overflow: hidden; }
      .pane-profiles-panel-pane-4 .views-row .views-field-field-profile-image img,
      .pane-profiles-panel-pane-5 .views-row .views-field-field-profile-image img {
        width: 210px; }
        @media screen and (min-width: 768px) {
          .pane-profiles-panel-pane-4 .views-row .views-field-field-profile-image img,
          .pane-profiles-panel-pane-5 .views-row .views-field-field-profile-image img {
            width: 100%;
            height: 280px;
            min-height: 280px; } }
        @media screen and (min-width: 992px) {
          .pane-profiles-panel-pane-4 .views-row .views-field-field-profile-image img,
          .pane-profiles-panel-pane-5 .views-row .views-field-field-profile-image img {
            height: 100%;
            min-height: 100%;
            max-height: 260px; } }
        @media screen and (min-width: 992px) {
          .pane-profiles-panel-pane-4 .views-row .views-field-field-profile-image img,
          .pane-profiles-panel-pane-5 .views-row .views-field-field-profile-image img {
            max-height: 315px; } }
    .pane-profiles-panel-pane-4 .views-row .views-field-field-phone,
    .pane-profiles-panel-pane-5 .views-row .views-field-field-phone {
      display: none; }
      .pane-profiles-panel-pane-4 .views-row .views-field-field-phone .field-content,
      .pane-profiles-panel-pane-5 .views-row .views-field-field-phone .field-content {
        display: inline; }
    .pane-profiles-panel-pane-4 .views-row .views-field-field-personal-statement,
    .pane-profiles-panel-pane-5 .views-row .views-field-field-personal-statement {
      clear: both; }

.pane-profiles-panel-pane-4 h3 {
  font-size: 28px;
  padding-bottom: 15px; }

.pane-profiles-panel-pane-4 .views-row {
  margin-bottom: 30px; }

.pane-profiles-panel-pane-4 .views-field-field-professional-suffix {
  display: none;
  width: 100%;
  height: 100px;
  position: relative;
  top: -100px;
  line-height: 25px; }
  .pane-profiles-panel-pane-4 .views-field-field-professional-suffix:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: #b31b1b;
    mix-blend-mode: multiply; }
  .pane-profiles-panel-pane-4 .views-field-field-professional-suffix a {
    width: 100%;
    height: 100%;
    color: #fff;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    padding: 2px; }
  .pane-profiles-panel-pane-4 .views-field-field-professional-suffix h4 {
    color: #fff;
    font-family: 'Open Sans', sans-serrif;
    z-index: 2;
    position: relative;
    font-size: 14px; }
  .pane-profiles-panel-pane-4 .views-field-field-professional-suffix p {
    color: #fff;
    z-index: 2;
    position: relative;
    line-height: 1.4;
    margin-top: 3px; }

.pane-profiles-panel-pane-4 .views-field-nothing {
  display: none; }

.pane-profiles-panel-pane-4 .leadership-bio-info {
  font-size: 16px;
  line-height: 25px;
  padding-bottom: 45px; }

.pane-profiles-panel-pane-4 .smltri:before {
  content: " ";
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #ac1713;
  position: absolute;
  bottom: -6%;
  left: 40%;
  bottom: 5.5%; }
  @media screen and (min-width: 768px) {
    .pane-profiles-panel-pane-4 .smltri:before {
      bottom: 5.5%; } }
  @media screen and (min-width: 992px) {
    .pane-profiles-panel-pane-4 .smltri:before {
      bottom: -21%; } }
  @media screen and (min-width: 1200px) {
    .pane-profiles-panel-pane-4 .smltri:before {
      bottom: -19%; } }

.pane-profiles-panel-pane-5 .view-content > h3,
.pane-profiles-panel-pane-6 .view-content > h3 {
  clear: both; }

.pane-profiles-panel-pane-5 .views-field-field-biographical-info,
.pane-profiles-panel-pane-6 .views-field-field-biographical-info {
  clear: both;
  display: none; }

.pane-profiles-panel-pane-5 .views-field-field-professional-suffix,
.pane-profiles-panel-pane-6 .views-field-field-professional-suffix {
  display: none;
  width: 100%;
  height: 100px;
  position: relative;
  top: -100px;
  line-height: 25px; }
  .pane-profiles-panel-pane-5 .views-field-field-professional-suffix:after,
  .pane-profiles-panel-pane-6 .views-field-field-professional-suffix:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: #b31b1b;
    mix-blend-mode: multiply; }
  .pane-profiles-panel-pane-5 .views-field-field-professional-suffix .field-content,
  .pane-profiles-panel-pane-6 .views-field-field-professional-suffix .field-content {
    width: 100%;
    height: 100%;
    color: #fff;
    display: inline-block;
    text-align: center; }
  .pane-profiles-panel-pane-5 .views-field-field-professional-suffix h4,
  .pane-profiles-panel-pane-6 .views-field-field-professional-suffix h4 {
    color: #fff;
    margin-top: 36px;
    font-family: 'Open Sans', sans-serrif;
    text-align: center;
    z-index: 2;
    position: relative;
    font-size: 14px; }
    @media screen and (min-width: 768px) {
      .pane-profiles-panel-pane-5 .views-field-field-professional-suffix h4,
      .pane-profiles-panel-pane-6 .views-field-field-professional-suffix h4 {
        font-size: 16px; } }

.pane-profiles-panel-pane-5 nav,
.pane-profiles-panel-pane-6 nav {
  width: 100%;
  float: left; }

.pane-profiles-panel-pane-5 .infoarea,
.pane-profiles-panel-pane-6 .infoarea {
  font-size: 16px;
  line-height: 25px;
  padding-bottom: 45px;
  float: left; }
  .pane-profiles-panel-pane-5 .infoarea h4,
  .pane-profiles-panel-pane-6 .infoarea h4 {
    margin-top: 40px; }
  .pane-profiles-panel-pane-5 .infoarea p,
  .pane-profiles-panel-pane-6 .infoarea p {
    margin-top: 25px;
    float: left; }
  .pane-profiles-panel-pane-5 .infoarea a,
  .pane-profiles-panel-pane-6 .infoarea a {
    font-size: 14px; }

.pane-profiles-panel-pane-5 .smltri:before {
  content: " ";
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #ac1713;
  position: absolute;
  bottom: 6.5%;
  left: 40%; }
  @media screen and (min-width: 768px) {
    .pane-profiles-panel-pane-5 .smltri:before {
      bottom: -6.5%; } }
  @media screen and (min-width: 992px) {
    .pane-profiles-panel-pane-5 .smltri:before {
      bottom: -9%; } }
  @media screen and (min-width: 1200px) {
    .pane-profiles-panel-pane-5 .smltri:before {
      bottom: -7.5%; } }

.pane-profiles-panel-pane-6 h2 {
  margin-bottom: 20px; }

.pane-profiles-panel-pane-6 .views-row {
  min-height: 225px; }
  .pane-profiles-panel-pane-6 .views-row p.display-name {
    font-weight: bold;
    padding-top: 8px; }
  .pane-profiles-panel-pane-6 .views-row:not(.smltri) .views-field-field-biographical-info + .views-field-field-profile-image {
    position: relative;
    cursor: pointer; }
    .pane-profiles-panel-pane-6 .views-row:not(.smltri) .views-field-field-biographical-info + .views-field-field-profile-image:after {
      content: " ";
      position: absolute;
      background: url(../images/sprite/profile_white.png) no-repeat;
      background-size: 2.6rem;
      background-position: 0px 0px;
      border-width: 12px;
      z-index: 1;
      bottom: 2px;
      left: 82px;
      width: 30px;
      height: 30px; }
  .pane-profiles-panel-pane-6 .views-row:not(.smltri):hover .views-field-field-biographical-info + .views-field-field-profile-image {
    position: relative; }
    .pane-profiles-panel-pane-6 .views-row:not(.smltri):hover .views-field-field-biographical-info + .views-field-field-profile-image:after {
      background: url(../images/sprite/profile.png) no-repeat;
      background-size: 2.6rem;
      background-position: 0px 0px; }
    .pane-profiles-panel-pane-6 .views-row:not(.smltri):hover .views-field-field-biographical-info + .views-field-field-profile-image img {
      outline-style: solid;
      outline-color: #e87722;
      outline-width: 3px; }
  .pane-profiles-panel-pane-6 .views-row .views-field-field-profile-image img {
    height: 155px;
    width: auto;
    min-width: 110px; }

.pane-profiles-panel-pane-6 .smltri .views-field-field-biographical-info + .views-field-field-profile-image {
  position: relative; }
  .pane-profiles-panel-pane-6 .smltri .views-field-field-biographical-info + .views-field-field-profile-image:before {
    position: absolute;
    content: " ";
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #ac1713;
    bottom: -22px;
    left: 38px; }
  .pane-profiles-panel-pane-6 .smltri .views-field-field-biographical-info + .views-field-field-profile-image:after {
    content: " ";
    position: absolute;
    background: url(../images/sprite/profile.png) no-repeat;
    background-color: transparent;
    background-size: 2.6rem;
    background-position: 0px 0px;
    border-width: 12px;
    bottom: 2px;
    left: 82px;
    width: 30px;
    height: 30px; }
  .pane-profiles-panel-pane-6 .smltri .views-field-field-biographical-info + .views-field-field-profile-image + .views-field-nothing p.display-name {
    padding: 0;
    margin: 0;
    display: none; }
  .pane-profiles-panel-pane-6 .smltri .views-field-field-biographical-info + .views-field-field-profile-image img {
    outline-style: solid;
    outline-color: #ac1713;
    outline-width: 3px; }

.pane-profiles-panel-pane-6 .smltri:hover .views-field-field-biographical-info + .views-field-field-profile-image:after {
  background: url(../images/sprite/profile.png) no-repeat;
  background-color: transparent;
  background-size: 2.6rem;
  background-position: 0px 0px; }

.pane-profiles-panel-pane-6 .smltri:hover .views-field-field-biographical-info + .views-field-field-profile-image img {
  outline-color: #ac1713 !important; }

.pane-profiles-panel-pane-6 .infoarea {
  clear: both;
  margin-top: -15px; }
  .pane-profiles-panel-pane-6 .infoarea h4 {
    margin: 0; }
  .pane-profiles-panel-pane-6 .infoarea .field-content {
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px; }
  .pane-profiles-panel-pane-6 .infoarea .display-bio p {
    margin-top: 8px; }

.profile_icon {
  position: relative; }
  .profile_icon:after {
    content: " ";
    position: absolute;
    background: url(../images/sprite/profile.png) no-repeat;
    background-color: transparent;
    background-size: 2.6rem;
    background-position: 0px 0px;
    border-width: 12px;
    z-index: 1;
    bottom: 2px;
    left: 82px;
    width: 30px;
    height: 30px; }

.pane-profiles-panel-pane-7 {
  display: inline-block;
  padding-top: 35px; }
  .pane-profiles-panel-pane-7 .views-field-nothing {
    padding: 1rem 0;
    background: #fff;
    font-size: 16px;
    line-height: 25px; }

.shownm {
  display: block !important; }

#biblio-header {
  height: 20px; }

.pane-clinical-trials-panel-pane-1 .views-row {
  border-bottom: 1px solid #dcdcdc;
  margin: 0 0 10px 0;
  padding: 0 0 20px 0; }
  .pane-clinical-trials-panel-pane-1 .views-row h2 {
    margin-bottom: 20px; }
  .pane-clinical-trials-panel-pane-1 .views-row .trial-title {
    padding-bottom: 10px;
    font-size: 17px; }
  .pane-clinical-trials-panel-pane-1 .views-row .views-field {
    padding-bottom: 2px; }
    .pane-clinical-trials-panel-pane-1 .views-row .views-field .views-label {
      font-weight: 600; }
    .pane-clinical-trials-panel-pane-1 .views-row .views-field .field-content {
      display: inline; }
  .pane-clinical-trials-panel-pane-1 .views-row .views-field-field-trial-source-link {
    padding-top: 10px; }

.pane-clinical-trials-panel-pane-1 .views-field-nothing {
  float: left; }

.pane-clinical-trials-panel-pane-1 .views-field-field-trial-path {
  float: left; }

.pane-clinical-trials-panel-pane-1 .views-field-field-trial-key-eligibility ul {
  padding-left: 20px; }

.pane-clinical-trials-panel-pane-1 .views-field-field-trial-path .btn-sml {
  margin-left: 15px; }

.pane-clinical-trials-panel-pane-1 .btn-sml {
  background-color: #cf4520;
  color: #fff;
  border-radius: 10px;
  height: 30px;
  line-height: 30px;
  padding-bottom: 0;
  padding-top: 0;
  font-size: 13px; }
  .pane-clinical-trials-panel-pane-1 .btn-sml:hover {
    text-decoration: underline; }

#views-exposed-form-clinical-trials-panel-pane-1 .views-widget-filter-tid {
  width: 100%;
  padding: 0;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd; }
  #views-exposed-form-clinical-trials-panel-pane-1 .views-widget-filter-tid a {
    width: 100%;
    float: left;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    height: 75px;
    line-height: 75px;
    text-align: center;
    font-size: 18px;
    color: #555;
    font-weight: bold; }
    @media screen and (min-width: 992px) {
      #views-exposed-form-clinical-trials-panel-pane-1 .views-widget-filter-tid a {
        width: 50%; } }
  #views-exposed-form-clinical-trials-panel-pane-1 .views-widget-filter-tid a.active {
    background: #ddd url("../images/orngarr.jpg") right 23px center no-repeat !important; }

h2.cttitle {
  width: 100%;
  display: block;
  background-color: #ddd;
  color: #000;
  height: 42px;
  padding-left: 22px;
  padding-top: 13px;
  font-size: 18px;
  margin: 25px 0; }

a.anclinks {
  float: left;
  width: 100%;
  color: #b31b1b;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-weight: bold;
  padding: 15px 120px;
  min-height: 120px;
  border: 1px solid #ddd;
  background: url("../images/grydwn.jpg") center 92% no-repeat; }
  @media screen and (min-width: 992px) {
    a.anclinks {
      width: 50%; } }
  a.anclinks:first-child {
    border-right: none; }
  a.anclinks:hover {
    background: url("../images/dwnred.jpg") center 92% no-repeat; }

p.calldiff {
  font-size: 19px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding: 40px 0 0 0;
  float: left; }

a.findiff {
  width: 100%;
  margin: 10px 10px 0 0;
  background-color: #cf4520;
  float: left;
  margin-bottom: 30px; }
  @media screen and (min-width: 992px) {
    a.findiff {
      width: 32%; } }
  a.findiff:hover {
    color: #fff; }
  a.findiff h3 {
    color: #fff;
    padding: 35px 25px 0;
    font-size: 40px;
    min-height: 205px; }
  a.findiff p {
    color: #fff;
    padding: 0 25px; }
  a.findiff img {
    width: 90%;
    margin: 0 auto 20px;
    display: block; }

a.findiff.last {
  margin-right: 0; }

.reseff {
  background-color: #cf4520;
  width: 100%;
  margin: 10px 10px 100px 0;
  float: left; }
  @media screen and (min-width: 992px) {
    .reseff {
      width: 32%; } }
  .reseff:hover {
    color: #fff; }
  .reseff h3 {
    color: #fff;
    line-height: 40px;
    font-size: 30px;
    padding: 25px;
    margin: 0;
    min-height: 175px; }
  .reseff:after {
    content: " ";
    display: block;
    float: left;
    width: 100%;
    height: 120px;
    background: #b31b1b url("../images/svg/lifesaver.svg") center center no-repeat;
    color: #fff; }

.reseff.chart:after {
  background-image: url("../images/svg/graph.svg"); }

.reseff.eye:after {
  background-image: url("../images/svg/eye.svg"); }

.reseff.external-link:after {
  content: " "; }

#qualfrom {
  height: 3700px;
  max-width: 100%;
  border: 0; }

.cta-icon {
  -webkit-transform: scale3d(1, 1, 1); }

.form-control {
  max-width: none; }

.btn-sml {
  background-color: #cf4520;
  color: #fff;
  border-radius: 10px;
  height: 30px;
  line-height: 30px;
  padding-bottom: 0;
  padding-top: 0;
  font-size: 13px; }
  .btn-sml:hover {
    text-decoration: underline; }

.pane-conditions-view-panel-pane-3, .pane-conditions-view-panel-pane-2 {
  float: left; }
  .pane-conditions-view-panel-pane-3 .views-row, .pane-conditions-view-panel-pane-2 .views-row {
    float: left;
    width: 100%; }
  .pane-conditions-view-panel-pane-3 .views-field-title, .pane-conditions-view-panel-pane-2 .views-field-title {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px; }
  .pane-conditions-view-panel-pane-3 .views-field-field-condition-image, .pane-conditions-view-panel-pane-2 .views-field-field-condition-image {
    float: right;
    margin: 0 0 10px 40px; }
  .pane-conditions-view-panel-pane-3 .views-field-path, .pane-conditions-view-panel-pane-2 .views-field-path {
    margin-top: 25px; }

.pane-conditions-view-panel-pane-3 {
  padding-top: 46px;
  margin-top: 35px;
  border-top: 1px solid #d8d8d8; }

#related-content-sidebar div.panel-pane {
  margin-bottom: 0 !important; }

#related-content-sidebar .pane-title {
  line-height: 1.5; }

.pane-conditions-panel-pane-1 span.field-content {
  list-style: disc outside none;
  display: list-item;
  margin-left: 20px; }

#fincont, #reseff {
  display: block;
  float: left;
  width: 100%; }

.node-biblio div.submitted {
  display: none; }

/* Events Page Style */
.pane-news-panel-pane-7 .view-teaser {
  width: 100%;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px; }
  .pane-news-panel-pane-7 .view-teaser .views-field-field-event-date-3 {
    float: left;
    width: 125px;
    height: 100px;
    background-color: #ddd;
    margin-right: 30px;
    margin-bottom: 15px; }
    .pane-news-panel-pane-7 .view-teaser .views-field-field-event-date-3 span {
      line-height: 1; }
    .pane-news-panel-pane-7 .view-teaser .views-field-field-event-date-3 .mondate {
      float: left;
      text-transform: uppercase;
      width: 100%;
      text-align: center;
      font-size: 22px;
      margin-top: 10px; }
    .pane-news-panel-pane-7 .view-teaser .views-field-field-event-date-3 .daydate {
      float: left;
      width: 100%;
      text-align: center;
      font-size: 40px;
      font-weight: bold; }
    .pane-news-panel-pane-7 .view-teaser .views-field-field-event-date-3 .yrdate {
      float: left;
      width: 100%;
      text-align: center;
      font-size: 16px;
      margin-top: 1px; }
    .pane-news-panel-pane-7 .view-teaser .views-field-field-event-date-3:after {
      position: relative;
      top: 30px;
      left: 100%;
      border-color: transparent #ddd;
      border-style: solid;
      border-width: 20px 0px 20px 20px;
      height: 1px;
      width: 0px;
      content: " ";
      display: block; }
  .pane-news-panel-pane-7 .view-teaser h3 {
    margin-top: 0;
    font-size: 16px;
    margin-top: 5px; }
  .pane-news-panel-pane-7 .view-teaser .views-label {
    float: left;
    font-weight: bold;
    padding-right: 5px; }

.pane-node-field-related-treatments, .pane-profiles-panel-pane-6, .pane-node-field-treatment-goals {
  border-top: 1px solid #ddd; }

.pane-weight-panel-pane-1 .pane-title,
.pane-weight-panel-pane-2 .pane-title {
  padding-bottom: 25px; }

.pane-weight-panel-pane-1 table caption,
.pane-weight-panel-pane-2 table caption {
  font-size: 20px;
  color: #b31b1b;
  padding-bottom: 10px;
  font-weight: 600;
  text-align: left; }

/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }
